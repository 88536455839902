import React, { Component } from "react";

class FeelingProud extends Component {
  render() {
    //const theme = this.props.theme;
    return (
      <svg width="711" height="670" viewBox="0 0 711 670" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M516.19 380.198V565.281L547.125 615.735L549.48 619.572H671.544L674.011 380.198H516.19Z" fill="#E6E6E6"/>
<path opacity="0.1" d="M516.19 380.198V565.281L547.125 615.735L549.024 380.198H516.19Z" fill="black"/>
<path d="M221.507 375.262V560.345L190.572 610.799L188.217 614.636H66.1525L63.6862 375.262H221.507Z" fill="#E6E6E6"/>
<path opacity="0.1" d="M221.507 375.262V560.345L190.572 610.799L188.673 375.262H221.507Z" fill="black"/>
<path d="M711 371.561V382.665H47.6574V366.625L109.306 334.543H656.749L711 371.561Z" fill="#E6E6E6"/>
<path opacity="0.1" d="M711 371.561V382.665H47.6574V366.625L711 371.561Z" fill="black"/>
<path d="M142.597 427.085H113.005L100.675 418.448H156.16L142.597 427.085Z" fill="#3F3D56"/>
<path d="M142.597 462.868H113.005L100.675 454.231H156.16L142.597 462.868Z" fill="#3F3D56"/>
<path d="M142.597 513.458H113.005L100.675 504.82H156.16L142.597 513.458Z" fill="#3F3D56"/>
<path d="M142.597 564.047H113.005L100.675 555.41H156.16L142.597 564.047Z" fill="#3F3D56"/>
<path d="M595.1 432.021H624.692L637.022 423.384H581.537L595.1 432.021Z" fill="#3F3D56"/>
<path d="M595.1 467.804H624.692L637.022 459.166H581.537L595.1 467.804Z" fill="#3F3D56"/>
<path d="M595.1 518.394H624.692L637.022 509.756H581.537L595.1 518.394Z" fill="#3F3D56"/>
<path d="M595.1 568.982H624.692L637.022 560.345H581.537L595.1 568.982Z" fill="#3F3D56"/>
<path d="M615.251 120.513H366.132C364.894 120.513 363.669 120.757 362.526 121.231C361.383 121.704 360.344 122.399 359.469 123.274C358.594 124.15 357.901 125.189 357.427 126.333C356.954 127.477 356.71 128.703 356.71 129.942V297.879C356.71 299.117 356.954 300.343 357.427 301.487C357.901 302.631 358.594 303.671 359.469 304.546C360.344 305.422 361.383 306.116 362.526 306.59C363.669 307.064 364.894 307.307 366.132 307.307H460.7L457.157 329.944C457.157 329.944 436.909 340.709 451.146 341.025C465.382 341.342 532.452 341.025 532.452 341.025C532.452 341.025 545.423 341.025 524.859 329.628L521.526 307.307H615.251C616.488 307.307 617.714 307.064 618.857 306.59C620 306.116 621.039 305.422 621.914 304.546C622.788 303.671 623.482 302.631 623.956 301.487C624.429 300.343 624.673 299.117 624.673 297.879V129.942C624.673 128.703 624.429 127.477 623.956 126.333C623.482 125.189 622.788 124.15 621.914 123.274C621.039 122.399 620 121.704 618.857 121.231C617.714 120.757 616.488 120.513 615.251 120.513V120.513Z" fill="#3F3D56"/>
<path d="M615.656 130.486H365.727V270.423H615.656V130.486Z" fill="#F2F2F2"/>
<path d="M490.691 127.637C491.565 127.637 492.273 126.928 492.273 126.054C492.273 125.18 491.565 124.471 490.691 124.471C489.818 124.471 489.11 125.18 489.11 126.054C489.11 126.928 489.818 127.637 490.691 127.637Z" fill="#F2F2F2"/>
<path d="M490.691 300.5C494.011 300.5 496.702 297.807 496.702 294.485C496.702 291.163 494.011 288.47 490.691 288.47C487.372 288.47 484.68 291.163 484.68 294.485C484.68 297.807 487.372 300.5 490.691 300.5Z" fill="#F2F2F2"/>
<path d="M481.325 358.122V361.288H311.12V358.755L311.354 358.122L315.549 346.724H477.845L481.325 358.122Z" fill="#3F3D56"/>
<path d="M546.825 355.022C546.515 356.349 545.344 357.748 542.7 359.071C533.209 363.82 513.91 357.805 513.91 357.805C513.91 357.805 499.041 355.272 499.041 348.624C499.458 348.338 499.894 348.078 500.344 347.848C504.335 345.735 517.565 340.522 541.024 348.069C542.752 348.612 544.296 349.624 545.483 350.993C546.009 351.511 546.405 352.146 546.638 352.846C546.872 353.547 546.936 354.292 546.825 355.022Z" fill="#3F3D56"/>
<path opacity="0.1" d="M546.825 355.022C535.208 359.477 524.853 359.809 514.227 352.423C510.208 349.45 505.342 347.846 500.344 347.848C504.335 345.735 517.565 340.522 541.024 348.069C542.752 348.612 544.296 349.624 545.483 350.993C546.009 351.511 546.405 352.146 546.638 352.846C546.872 353.547 546.936 354.292 546.825 355.022Z" fill="black"/>
<path d="M532.259 352.423C534.531 352.423 536.372 351.856 536.372 351.156C536.372 350.457 534.531 349.89 532.259 349.89C529.988 349.89 528.147 350.457 528.147 351.156C528.147 351.856 529.988 352.423 532.259 352.423Z" fill="#F2F2F2"/>
<path opacity="0.1" d="M481.325 358.122V361.288H311.12V358.755L311.354 358.122H481.325Z" fill="black"/>
<path d="M94.6365 0.000152972C92.9091 -0.141778 91.1794 0.24491 89.6765 1.10898C88.2009 2.12026 87.2709 3.89191 86.3215 5.54054C82.6918 11.9192 77.7779 17.4739 71.8911 21.8529C67.6033 24.9964 62.1519 28.9159 62.8855 34.7117C63.2725 36.6257 63.9791 38.4608 64.9756 40.1398C68.9311 48.1305 79.7252 54.3057 78.6635 63.4239C82.5935 56.8398 77.3369 53.5842 81.2668 47.0002C83.1384 43.8648 86.3896 40.3254 89.2152 42.2945C90.1614 42.9538 90.7826 44.1363 91.7677 44.7148C94.1183 46.0952 96.619 43.4562 98.6998 41.587C105.875 35.1413 116.078 36.8299 125.007 38.8383C129.222 39.7864 133.852 41.0303 136.343 45.0619C139.62 50.3626 133.231 56.0875 131.617 62.0805C131.485 62.5911 131.479 63.1261 131.598 63.6397C131.718 64.1533 131.961 64.6302 132.305 65.0296C132.649 65.429 133.085 65.7391 133.575 65.9332C134.065 66.1274 134.594 66.1999 135.119 66.1445C137.798 65.8801 140.971 65.6633 141.13 64.4868C144.5 64.6069 148.63 64.2249 150.075 60.7125C150.516 59.3228 150.749 57.8754 150.767 56.4173C151.297 50.5173 153.799 45.1347 155.457 39.5193C157.115 33.9039 157.835 27.3231 155.038 22.3444C154.04 20.6879 152.805 19.1862 151.373 17.8867C135.498 2.51271 114.626 -0.0226678 94.6365 0.000152972Z" fill="#2F2E41"/>
<path d="M82.116 74.9407C82.116 74.9407 85.5685 103.733 72.9093 106.036C60.2502 108.339 84.4176 147.497 84.4176 147.497L141.959 154.407L128.149 108.339C128.149 108.339 118.943 104.884 124.697 81.8508C130.451 58.8171 82.116 74.9407 82.116 74.9407Z" fill="#C58C53"/>
<path d="M47.0156 569.588L51.6193 632.931H75.786L68.8818 569.588H47.0156Z" fill="#C58C53"/>
<path d="M224.244 449.813L227.696 502.79L254.166 491.274L243.808 447.51L224.244 449.813Z" fill="#C58C53"/>
<path d="M29.7531 327.735C29.7531 327.735 30.9039 394.532 37.809 416.414C44.714 438.296 43.5631 439.448 42.4123 442.903C41.2615 446.358 40.1106 444.055 40.1106 449.813C40.1106 455.571 37.809 546.554 40.1106 554.616C42.4123 562.678 33.2057 576.498 40.1106 577.65C47.0156 578.801 79.2389 577.65 80.3898 570.74C81.5406 563.83 71.1831 561.526 75.7864 556.919C80.3898 552.313 87.2948 457.875 87.2948 457.875L103.406 392.229L133.328 357.679H196.624L216.188 433.69C216.188 433.69 208.132 455.571 213.886 455.571C219.64 455.571 254.165 462.482 254.165 437.145C254.165 411.808 242.657 336.948 240.355 334.645C238.054 332.342 241.506 324.28 238.054 320.825C234.601 317.37 194.322 293.184 179.361 288.578C164.4 283.971 143.413 278.488 143.413 278.488L29.7531 327.735Z" fill="#2F2E41"/>
<path d="M254.165 482.06C254.165 482.06 236.903 476.302 228.847 493.577C220.791 510.852 224.244 515.459 224.244 515.459C224.244 515.459 250.713 524.672 255.316 520.066C257.319 518.061 263.679 517.365 270.225 517.218C280.195 516.994 282.848 502.878 273.432 499.591C273.151 499.493 272.866 499.408 272.579 499.335C263.372 497.032 254.165 482.06 254.165 482.06Z" fill="#2F2E41"/>
<path d="M108.01 93.9434C127.077 93.9434 142.535 78.4747 142.535 59.393C142.535 40.3113 127.077 24.8425 108.01 24.8425C88.9421 24.8425 73.4847 40.3113 73.4847 59.393C73.4847 78.4747 88.9421 93.9434 108.01 93.9434Z" fill="#C58C53"/>
<path d="M63.1273 120.432C63.1273 120.432 119.518 131.949 133.328 113.522C147.138 95.0951 152.892 165.348 152.892 165.348L159.797 239.055L149.44 279.364C149.44 279.364 95.3506 323.128 78.0881 326.583C60.8256 330.038 34.3564 332.342 34.3564 332.342C34.3564 332.342 42.4123 204.505 42.4123 202.202C42.4123 199.898 63.1273 120.432 63.1273 120.432Z" fill="#6C63FF"/>
<path d="M80.2771 98.8327C80.2771 98.8327 67.7306 91.6401 64.2781 98.5502C60.8256 105.46 28.6023 122.735 25.1498 122.735C21.6973 122.735 32.0548 218.325 27.4515 229.842C22.8482 241.359 0.982364 325.431 13.6415 332.342C26.3007 339.252 17.094 325.431 29.7532 342.707C42.4123 359.982 104.557 359.982 108.01 353.072C111.462 346.162 80.3898 294.336 86.1439 245.965C91.8981 197.595 101.105 130.797 93.0489 121.584C84.9931 112.37 80.2771 98.8327 80.2771 98.8327Z" fill="#2F2E41"/>
<path d="M121.82 106.612L123.423 101.091C123.423 101.091 171.306 116.977 173.607 127.342C175.909 137.707 174.758 210.263 166.702 216.022C158.646 221.78 147.138 230.994 155.194 244.814C163.25 258.634 172.456 273.606 179.361 274.757C186.266 275.909 198.926 283.971 195.473 292.033C192.021 300.094 150.591 285.123 150.591 285.123C150.591 285.123 122.971 264.392 124.121 232.145C125.272 199.898 121.82 106.612 121.82 106.612Z" fill="#2F2E41"/>
<path d="M162.099 242.51L134.479 294.336C134.479 294.336 94.1997 335.797 117.216 340.403C140.233 345.01 152.892 302.398 152.892 302.398L182.814 259.786L162.099 242.51Z" fill="#C58C53"/>
<path d="M96.3183 9.0402C94.9267 8.937 93.5328 9.20756 92.2807 9.82391C91.1485 10.6514 90.2155 11.7215 89.5496 12.9561C86.48 17.5768 82.4787 21.5041 77.8026 24.4856C74.3121 26.7074 69.8746 29.4776 70.4717 33.5741C70.7959 34.9475 71.3729 36.2486 72.1731 37.4106C75.4042 43.1155 76.6905 49.7184 75.8373 56.2202L85.4349 42.2596C86.9584 40.0434 89.605 37.5418 91.9053 38.9336C92.6754 39.3996 93.1812 40.2354 93.9831 40.6442C95.8966 41.6199 97.9322 39.7547 99.6261 38.4335C105.467 33.8777 113.773 35.0713 121.041 36.4907C124.472 37.1608 128.241 38.04 130.27 40.8896C133.64 45.6249 130.121 52.4671 132.088 57.9376C132.639 57.5518 133.106 57.0592 133.463 56.4892C133.82 55.9191 134.059 55.2831 134.166 54.6189C136.91 54.7038 140.272 54.4338 141.447 51.9513C141.805 50.9784 141.995 49.9519 142.011 48.9154C142.442 44.7454 144.479 40.941 145.829 36.9721C147.178 33.0032 147.765 28.3518 145.488 24.8329C144.655 23.6407 143.649 22.5788 142.504 21.6823C129.581 10.816 112.591 9.02407 96.3183 9.0402Z" fill="#2F2E41"/>
<path d="M162.099 122.735L172.821 125.584C172.821 125.584 197.775 189.533 192.02 233.297C186.266 277.061 182.814 267.847 182.814 267.847C182.814 267.847 173.607 247.117 152.892 251.724L162.099 122.735Z" fill="#2F2E41"/>
<path d="M48.6101 624.559C45.8349 628.333 46.3913 633.728 43.933 637.716C41.8021 641.173 37.7863 642.986 35.121 646.049C34.2705 647.066 33.5123 648.158 32.8554 649.31C30.3829 653.455 28.3733 658.766 30.8083 662.933C32.7672 666.285 36.8846 667.595 40.6607 668.488C45.4335 669.616 50.3925 670.519 55.2204 669.657C60.0483 668.795 64.7667 665.836 66.4881 661.241C66.8265 660.085 67.2286 658.949 67.6929 657.838C70.3097 652.683 78.5175 652.627 81.1956 647.503C83.0698 643.918 81.347 639.583 79.6248 635.922L74.3651 624.74C72.6197 621.029 65.5438 623.163 61.855 622.274C57.0441 621.114 52.071 619.836 48.6101 624.559Z" fill="#2F2E41"/>
<path d="M10.189 265.544L58.5239 340.403C58.5239 340.403 88.4455 378.409 97.6522 361.134C106.859 343.858 65.4289 315.066 65.4289 315.066L34.3564 257.482L10.189 265.544Z" fill="#C58C53"/>
<path d="M34.3564 125.039L25.1498 122.735C25.1498 122.735 6.73648 128.494 2.13316 152.679C-2.47016 176.865 0.982323 273.606 6.73648 274.757C12.4906 275.909 35.1562 257.913 38.7843 261.728C42.4123 265.544 30.9039 245.965 34.3564 235.6C37.8089 225.235 34.3564 125.039 34.3564 125.039Z" fill="#2F2E41"/>
<path d="M467.036 176.446H437.291V183.612H467.036V176.446Z" fill="#6C63FF"/>
<path d="M570.04 176.446H559.574V183.612H570.04V176.446Z" fill="#6C63FF"/>
<path d="M590.971 176.446H580.506V183.612H590.971V176.446Z" fill="#6C63FF"/>
<path d="M548.558 176.446H477.501V183.612H548.558V176.446Z" fill="#6C63FF"/>
<path d="M429.58 222.75H399.835V229.916H429.58V222.75Z" fill="#6C63FF"/>
<path d="M532.584 222.75H522.118V229.916H532.584V222.75Z" fill="#6C63FF"/>
<path d="M553.515 222.75H543.049V229.916H553.515V222.75Z" fill="#6C63FF"/>
<path d="M511.102 222.75H440.045V229.916H511.102V222.75Z" fill="#6C63FF"/>
<path d="M552.414 192.432H522.669V199.598H552.414V192.432Z" fill="#6C63FF"/>
<path d="M592.624 192.432H562.879V199.598H592.624V192.432Z" fill="#6C63FF"/>
<path d="M410.301 192.432H399.835V199.598H410.301V192.432Z" fill="#6C63FF"/>
<path d="M431.232 192.432H420.767V199.598H431.232V192.432Z" fill="#6C63FF"/>
<path d="M512.754 192.432H441.698V199.598H512.754V192.432Z" fill="#6C63FF"/>
<path d="M490.721 207.866H460.977V215.032H490.721V207.866Z" fill="#6C63FF"/>
<path d="M450.511 207.866H420.767V215.032H450.511V207.866Z" fill="#6C63FF"/>
<path d="M410.301 207.866H399.835V215.032H410.301V207.866Z" fill="#6C63FF"/>
<path d="M592.624 207.866H582.158V215.032H592.624V207.866Z" fill="#6C63FF"/>
<path d="M571.692 207.866H500.636V215.032H571.692V207.866Z" fill="#6C63FF"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="711" height="670" fill="white"/>
</clipPath>
</defs>
</svg>

    );
  }
}

export default FeelingProud;
